
import { mapState, mapActions } from "vuex";
import { reduce, minBy } from "ramda";

import {
  saleTagMixin,
  gtmEventMixin,
  metaColourMixin,
  tagableMixin,
} from "~/mixins";
import { slugify, camelize } from "~/utils";

export default {
  mixins: [saleTagMixin, metaColourMixin, gtmEventMixin, tagableMixin],

  inject: { uid: { default: "" } },

  props: {
    from: { type: String, default: "" },
    product: { type: Object, required: true },
    selectedVariant: { type: Object, required: true },
    isRefined: { type: Boolean, default: false },
    position: { type: [Number, String], required: true },
  },

  computed: {
    ...mapState(["siteConfig"]),
    metafields() {
      return {
        ...this.selectedVariant.metafields,
        ...this.product?.metafields,
      };
    },
    minPriceVariant() {
      return !this.isRefined
        ? reduce(
            minBy(v => Number(v.price)),
            { price: Infinity },
            this.product.variants
          )
        : null;
    },
    metaColors() {
      const metaColorArgs = this.product.variants;
      return this.getMetaColors(metaColorArgs);
    },
    tags() {
      return this.product?.tags ?? [];
    },
    listname() {
      return this.source ? `${this.source} - ${this.uid}` : this.uid;
    },
    productImagesProps() {
      return {
        mainImage:
          this.selectedVariant.image ?? this.product.featuredImage ?? {},
        maxDiscount: this.maxDiscount,
        priorityCampaign: this.priorityCampaign,
        selectedVariant: this.selectedVariant,
        product: this.product,
        metafields: this.metafields,
        position: this.position,
        listname: this.listname,
      };
    },
    productColorProps() {
      return {
        colorSwatches: this.product.color_swatches,
        handle: this.product.handle,
      };
    },
    productPriceProps() {
      return {
        priorityCampaign: this.priorityCampaign,
        maxDiscount: this.maxDiscount,
        selectedVariant: this.selectedVariant,
        minPriceVariant: this.minPriceVariant,
        product: this.product,
      };
    },
    ratingProps() {
      return {
        handle: this.product.handle,
        reviewsAverage: this.metafields.reviews_average,
        reviewsCount: this.metafields.reviews_count,
        selectedVariantId: this.selectedVariant.id,
      };
    },
    title() {
      const feel = this.selectedOptions?.["comfort-feel"] ?? "";
      return this.oneProductMultipleFeels
        ? `${this.product.title} - ${feel}`
        : `${this.product.title}`;
    },
    selectedOptions() {
      const selectedOptions = this.selectedVariant?.selectedOptions ?? {};
      return Object.entries(selectedOptions).reduce(
        (acc, [k, v]) => ({
          ...acc,
          [slugify(camelize(k, true, " "))]: v.value ?? v,
        }),
        {}
      );
    },
    imagesPresent() {
      return Boolean(this.selectedVariant?.images);
    },
  },

  created() {
    const saleTitle = this.metafields?.product_campaign_badge_title;
    const saleColor = this.metafields?.product_campaign_badge_color;

    this.getSaleTag({
      tags: this.tags,
      campaign: { saleTitle, saleColor },
      price: this.selectedVariant.price,
      compareAtPrice: this.selectedVariant.compareAtPrice,
    });
  },

  methods: {
    ...mapActions("recommendations", ["trackBeacon"]),
    async handleClick(event) {
      const { source, id, recId } = this.product;
      if (source === "optimizely") {
        this.$optimizely.clickTracker(recId);
      }

      if (source?.includes("searchspring-")) {
        const [, tag] = this.product.source.split("searchspring-");
        await this.trackBeacon({ type: "click", tag, productId: id });
      }

      if (this.from !== "ShopifyProducts") {
        this.triggerGtmEvent();
      }

      this.$searchspring.trackProductClick(
        event.target.closest("a"),
        this.product.intellisuggestData,
        this.product.intellisuggestSignature
      );
    },

    triggerGtmEvent() {
      this.$gtm.gtagEvent("select_item", {
        item_list_id: this.uid,
        item_list_name: this.uid,
        items: [
          {
            item_id: this.selectedVariant?.sku,
            shopify_product_id: this.product?.id,
            item_name: this.product?.title,
            currency: "AUD",
            discount:
              this.selectedVariant?.compareAtPrice > this.selectedVariant?.price
                ? this.selectedVariant.compareAtPrice -
                  this.selectedVariant.price
                : 0,
            index: this.position >= 0 ? this.position + 1 : "",
            item_brand: this.product?.metafields?.brand_list ?? "",
            item_category: this.product?.metafields?.product_category ?? "",
            item_list_id: this.uid,
            item_list_name: this.uid,
            item_variant: this.selectedVariant?.selectedOptions?.size,
            price: this.selectedVariant?.price,
            quantity: 1,
          },
        ],
      });
    },
  },
};
